






































import {
  Component,
  Prop,
  PropSync,
  Vue,
} from 'vue-property-decorator';
import { Getter, namespace } from 'vuex-class';
import _ from 'lodash';
import moment from 'moment';

import Firm from '@models/firm/Firm';
import Employee from '@modules/declaration/entities/Employee';
import Job from '@modules/declaration/entities/Job';

const firmUpdateModule = namespace('firmUpdate');

@Component
export default class AssignmentEmployeeModal extends Vue {
  @PropSync('show', { type: Boolean, required: true })
  visible!: Boolean;

  @Prop({ type: Object })
  selectedItem!: Job;

  @Getter('currentFirm')
  currentFirm !: Firm;

  @firmUpdateModule.Getter('getNotAffectedEmployees')
  notAffected!: (id: number) => Employee[];

  @firmUpdateModule.Getter('getCurrentEmployees')
  currentEmployees!: (id: number) => Employee[];

  @firmUpdateModule.Getter('getJob')
  getJob!: (uuid: string) => Job;

  checked: boolean = false;
  selectedEmployees: Employee[] = [];
  beginDate: Date = TODAY;

  get selectedJob() {
    return this.getJob(this.selectedItem.uuid);
  }

  get currentNotAffected() {
    return this.notAffected(this.currentFirm.id);
  }

  get otherEmployees(): Employee[] {
    return this.currentEmployees(this.currentFirm.id);
  }

  get filterEmployees(): Employee[] {
    const tabEmployees = _.cloneDeep(this.otherEmployees);
    _.remove(tabEmployees, (o) => {
      let remove = o.endDate !== null && moment(o.endDate).isSameOrBefore(moment());
      remove = remove || !!this.selectedEmployees.find(e => e.uuid === o.uuid);
      if (this.checked) {
        remove = remove || this.currentNotAffected.find(e => e.uuid === o.uuid) === undefined;
      }

      return remove;
    });

    return _.orderBy(tabEmployees, ['label'], ['asc']);
  }

  close() {
    this.$emit('update:show', false);
  }

  valid() {
    this.$emit('valid', { selectedEmployees: this.selectedEmployees, beginDate: this.beginDate });
  }

  created() {
    this.checked = false;
    this.selectedEmployees = [];
    this.beginDate = TODAY;
  }
}
